/**
 * Adds the .page-scrolling class to the <body> when page is scrolled down past the #scroll-tracker.
 */
(() => {
  const scrollTracker = document.getElementById('scroll-tracker')
  const className = 'page-scrolling'

  const handleIntersection = ([entry]) => {
      if (entry.intersectionRatio < 1) {
          document.body.classList.add(className)
      }
      else {
          document.body.classList.remove(className)
      }
  }

  const observer = new IntersectionObserver(handleIntersection)
  observer.observe(scrollTracker)
})();


/**
 * Hides a form alements except the successful submit message.
 * Works with ajax forms built with Freeform plugin for Craft CMS.
 */
(() => {
  let ajaxForms = document.getElementsByClassName('js-freeform-ajax-form');

  for (let form of ajaxForms) {
    form.addEventListener('form.submit.success', (event) => {
        let formElements = event.form.querySelectorAll('*:not(.alert)');

        for (let formElement of formElements) {
          // Removing elements causes an error spitted to a console from the Freeform plugin side.
          // Not a dealbreaker though.
          // formElement.remove();

          formElement.style.display = 'none';
        }
      });
  }
})();
